import React from 'react'
import { graphql } from 'gatsby'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Row } from 'react-styled-flexboxgrid'
import {
  Markdown,
  Section,
  SignUpPageHeader
} from '../components'
import { PageLayout } from '../layouts'
import * as styles from '../styles/pages/sign-up'
import variables from '../styles/variables'
import winner from '../../assets/winner.svg'
import piggyBank from '../../assets/piggy-bank.svg'
import cyclist from '../../assets/cyclist.svg'

const propTypes = {
  data: PropTypes.object.isRequired
}

export default function SignUpForm(props) {
  const { data } = props
  const { allYaml } = data
  const { edges } = allYaml
  const { node } = _.first(edges)
  const whySignUpIcons = [winner, cyclist, piggyBank]
  const firstChild = (
    <SignUpPageHeader
      content={node}
    />
  )

  return (
    <PageLayout
      navbarProps={{ background: variables.colors.oracle.dark }}
      description={node.metaDescription}
      sharingTitle={node.title}
      firstChild={firstChild}
    >
      <Helmet>
        <title>{node.title}</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <styles.Container>
        <styles.TextWrapper>
          <Section>
            <styles.SubHeader>
              {node.whySignUpHeader}
            </styles.SubHeader>
            <Row>
              {_.map(node.whySignUpPoints, (item, i) => {
                return (
                  <styles.ItemCol sm={4} xs={12} key={i}>
                    <styles.ItemContainer>
                      <styles.ImageContainer>
                        <img src={whySignUpIcons[i]} alt=" " />
                      </styles.ImageContainer>
                      <styles.ItemText>
                        {item}
                      </styles.ItemText>
                    </styles.ItemContainer>
                  </styles.ItemCol>
                )
              })}
            </Row>
            <Row>
              {_.map(node.otherSignUpInfo, (info, i) => {
                return (
                  <styles.InfoCol sm={6} xs={12} key={i}>
                    <styles.SubHeader>
                      {info.title}
                    </styles.SubHeader>
                    <styles.Text>
                      <Markdown>
                        {info.text}
                      </Markdown>
                    </styles.Text>
                  </styles.InfoCol>
                )
              })}
            </Row>
          </Section>
        </styles.TextWrapper>
      </styles.Container>
    </PageLayout>
  )
}

SignUpForm.propTypes = propTypes

export const contentQuery = graphql`
  query GetSignUpFormContent {
    allYaml(filter: {file: {eq: "sign-up"}}) {
      edges {
        node {
          title
          header
          metaDescription
          signUpInfo
          signUpButton {
            text
            url
            info
          }
          consoleImage {
            childImageSharp {
              fluid(maxWidth: 1100, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }

          whySignUpHeader
          whySignUpPoints

          otherSignUpInfo {
            title
            text
          }
        }
      }
    }
  }
`
