import styled from 'styled-components'
import { Col } from 'react-styled-flexboxgrid'
import Img from 'gatsby-image'
import { Heading } from '../../components'
import variables from '../variables'
import { TextWrapper as BaseTextWrapper } from '../base'
import { headerStyles } from '../global'

export const PosedFormContainer = styled.div`
  animation: fadeIn 0.7s ease-in-out;
  margin-top: 50px;
`
export const Container = styled.div`
  position: relative;
  width: 100%;
  background: white;
  z-index: 999;
`
export const Header = styled(Heading)`
  animation: slideUp 0.5s ease-in-out;
  color: white;
`

export const SubHeader = styled(Heading)`
  animation: slideUp 0.5s ease-in-out;
  ${headerStyles.headerSmall};
`
export const Text = styled.div`
  animation: slideUp 0.5s ease-in-out 0.2s;
  animation-fill-mode: forwards;  
  opacity: 0;
  margin-bottom: 20px;
`
export const TextWrapper = styled(BaseTextWrapper)`
  color: ${variables.colors.silverTree.darker};
  padding-top: 100px !important;
  padding-bottom: 50px !important;
  position: relative;

  @media ${variables.media.sm} {
    padding-bottom: 80px !important;
  }
`

export const ConsoleImage = styled(Img)`
  max-width: 100%;
  border-radius: 8px;
`

export const SignupSubSection = styled.div`
  max-width: 450px;
  margin: 0 auto;
  @media ${variables.media.lg} {
    margin: 0;
    max-width: 100%;
  }
`

export const ItemText = styled.p`
  color: ${variables.colors.oracle.darker};
`
export const ItemCol = styled(Col)`
  padding-bottom: 30px;
`

export const InfoCol = styled(Col)`
  margin-top: 30px;
  @media ${variables.media.md} {
    margin-top: 50px;
    padding: 0 70px 0 8px;
  }
`

export const ItemContainer = styled.div`
  height: 100%;
  border: 1px solid ${variables.colors.silverTree.medium};
  border-radius: 6px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${variables.media.md} {
    margin-right: 20px;
  }
`
export const ImageContainer = styled.div`
  height: 75px;
`
